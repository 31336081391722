import { ReactNode } from 'react';

export interface ISpacer {
  height?: number | null;
  background?: string;
}

export const Spacer = ({ height, background }: ISpacer): ReactNode => (
  <div style={{ background, height: height ? `${height}px` : 'auto' }} />
);
